import React from "react"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo";

import img from "../images/404.svg"; 

import { createUseStyles} from "../styling";

const useStyles = createUseStyles({
  container: {
    display:"flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    height:"100vh",
    width: "100vw",
  },
  img: {
    maxWidth: "465px",
    margin:"0 auto",
    width: "100%",
  },
  h1: {
    color: "#3D495B",
    fontFamily: "inter",
    fontSize: "24px",
    fontWeight: 500,
    marginTop: "30px",
    marginBottom: "15px",
  },
  p: {
    color:"#8592A5",
    fontFamily: "inter",
    fontSize: "18px",
    fontWeight: 300,
  },
  a: {
    color:"#4C63B6",
    textDecoration: "none",
    fontWeight: 500,
  }
})

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    <>
      <SEO title={"404: Not Found"} lang={"en"} />
      <div className={classes.container}>
        <img src={img} className={classes.img}/>
        <h1 className={classes.h1}>We couldn't find the page you were looking for.</h1>
        <p className={classes.p}>Click here to go back <Link to={"/"} className={classes.a}>home</Link>.</p>
      </div>
    </>
  )
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
